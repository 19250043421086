<template>
	<div>
		<h3>{{ heading }}</h3>
		<p class="instructions">
			<svg-icon icon="arrow-all" fixed />
			<span class="touch-only">
				Select an image, followed by the matching view.
			</span>
			<span class="mouse-only">
				Select an image, followed by the matching view,
				<em>or</em>
				drag each image.
			</span>
		</p>
		<p class="instructions">
			<svg-icon icon="magnify" fixed />
			Double-click to preview.
		</p>
		<p class="instructions">
			Any remaining unmatched images will not be submitted.
		</p>
		<div class="matching-panes">
			<div class="your-images">
				<h4>Your images</h4>
				<div class="thumbnail-pane">
					<div
						v-for="thumbnail in unmatchedStudyImages"
						:key="thumbnail.imageId"
						v-dblclick="() => openLightbox(thumbnail)"
						class="thumbnail"
						:class="getThumbnailClass(thumbnail)"
						draggable="true"
						:style="{
							'background-image': `url(${getThumbnailUrl(thumbnail)})`,
						}"
						@click="selectThumbnail(thumbnail)"
						@dragstart="onDragStart(thumbnail, $event)"
					>
						<div class="thumbnail-text">
							<span>
								{{ thumbnail.simpleName }}
								{{ thumbnail.acquisitionTime | localizeDate({ forceUTC: false }) }}
							</span>
						</div>
					</div>
				</div>
				<a @click.prevent="addRemainingAsExtraImages">
					Add Remaining as Extra Images
				</a>
			</div>
			<div class="submission-images">
				<h4>Images for submission</h4>
				<div class="thumbnail-pane">
					<div
						v-for="thumbnail in reportImages"
						:key="thumbnail.reportTemplateImageViewId || thumbnail.imageId"
						class="thumbnail"
						:class="getThumbnailClass(thumbnail)"
						:style="{
							'background-image': `url(${getThumbnailUrl(thumbnail)})`,
						}"
						@click="onImageViewClick(thumbnail)"
						@dragover.prevent="onImageViewDragOver"
						@dragleave.prevent="onImageViewDragLeave"
						@drop.prevent="onImageViewDrop(thumbnail, $event)"
					>
						<div v-if="!thumbnail.imageId" class="dim-overlay"></div>
						<div class="thumbnail-text">
							<p>{{ thumbnail.name || thumbnail.extraReason }}</p>
							<div class="thumbnail-status">
								<p v-if="thumbnail.imageId" class="is-success">
									<svg-icon icon="check-circle" />
								</p>
								<p v-if="thumbnail.isRequired && !thumbnail.imageId" class="is-danger">
									Required
								</p>
								<p v-if="!thumbnail.isRequired && !thumbnail.imageId" class="is-warning">
									Optional
								</p>
							</div>
						</div>
						<svg-icon
							v-if="thumbnail.imageId"
							icon="close"
							class="clear-image"
							@click.native="unassignImage(thumbnail)"
						/>
					</div>
					<div
						class="thumbnail"
						:class="getThumbnailClass({ isExtra: true })"
						@click="onImageViewClick({ isExtra: true })"
						@dragover.prevent="onImageViewDragOver"
						@dragleave.prevent="onImageViewDragLeave"
						@drop.prevent="onImageViewDrop({ isExtra: true }, $event)"
					>
						<div class="dim-overlay"></div>
						<div class="thumbnail-text">
							<p>Extra Image</p>
						</div>
					</div>
				</div>
				<a @click.prevent="reportImagesNotMatching">
					Images not matching?
				</a>
			</div>
		</div>
	</div>
</template>

<script>
import api from '@services/api'
import { openLightbox } from '@dialogs/ImageLightbox.vue'
import { openPromptDlg } from '@dialogs/TextPromptDlg.vue'
import { findThumbnailUrl } from '@utils/urlUtility'
import { showAlert } from '@dialogs/MessageDlg.vue'
import service from '@services/salesService'
import workflow from '@services/workflow'

export default {
	name: 'TeleconsultationRequestMatchImages',
	props: {
		ids: {
			type: Array,
			required: true,
		},
		consultantId: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			studyImages: [],
			hasServerMatches: false,
			service,
		}
	},
	computed: {
		allowAttachmentOnly() {
			return this.service.saleEntry.allowAttachmentOnly
		},
		reportImages: {
			get() {
				return this.service.saleEntry.images
			},
			set(images) {
				this.service.saleEntry.images = images
			},
		},
		unmatchedStudyImages() {
			return this.studyImages.filter(thumbnail => {
				return !this.reportImages.some(image => image.imageId === thumbnail.imageId)
			})
		},
		selectedStudyImage() {
			return this.studyImages.find(thumbnail => thumbnail.isSelected)
		},
		heading() {
			if (this.hasServerMatches) {
				return (
					'Keystone has matched some of your submitted images to those accepted ' +
					'for this sale. Please review these matches, and match any additional ' +
					'images at this time.'
				)
			} else {
				return 'Please match your uploaded images to those accepted by this sale.'
			}
		},
	},
	watch: {
		reportImages: {
			handler(reportImages) {
				const isComplete = !reportImages.some(image => image.isRequired && !image.imageId)
				workflow.canGoNext = isComplete
			},
			immediate: true,
		},
	},
	async beforeRouteEnter(to, from, next) {
		if (!service.saleEntry) {
			next(false)
			return
		}

		workflow.canGoNext = false
		workflow.isLoading = true
		try {
			const ids = to.query.studyId.split(',')

			// Get sale template and validate studies against sale requirements
			const { hasServerMatches } = await service.getTemplate({
				studyIds: ids,
			})
			const { errors } = service.saleEntry
			if (errors.length) {
				showAlert(errors.join(' '))
				return next(false)
			}

			// Get study images
			const { studies } = await api.viewer.getStudy({ ids }, false)
			const studyImages = []
			for (let i = 0; i < studies.length; i++) {
				const study = studies[i]
				const addAcquisitionTime = thumbnail => {
					let acquisitionTime = ''
					const series = study.imageData.series.find(s => s.seriesId === thumbnail.seriesId)
					if (series) acquisitionTime = series.images[0].acquisitionTime
					return { ...thumbnail, acquisitionTime }
				}
				studyImages.push(...study.imageData.thumbnails.map(addAcquisitionTime))
			}

			const { images, allowAttachmentOnly } = service.saleEntry
			const requiredImages = images.filter(image => image.isRequired)
			const cannotAddImages = service.saleEntryStatus === 'saleEntryAlreadyHasImages' && (!images || !images.length)
			// if user provided no images and none are required anyway, skip matching
			const skipImageMatching =
				(!studyImages.length && (!requiredImages.length || allowAttachmentOnly)) || cannotAddImages
			if (skipImageMatching)
				return next({
					name: 'request-submit-sale',
					query: to.query,
				})

			next(vm => {
				vm.hasServerMatches = hasServerMatches
				vm.studyImages = studyImages
			})
		} finally {
			workflow.isLoading = false
		}
	},
	mounted() {
		workflow.nextRoute = {
			name: 'request-submit-sale',
			query: this.$route.query,
		}
	},
	methods: {
		openLightbox,
		getThumbnailClass(thumbnail) {
			const classes = []
			const isUnmatched = thumbnail.isExtra || (thumbnail.reportTemplateImageViewId && !thumbnail.imageId)
			if (isUnmatched) classes.push('is-unmatched')
			if (!isUnmatched && thumbnail.reportTemplateImageViewId) classes.push('is-matched')
			if (isUnmatched && this.selectedStudyImage) classes.push('is-target')
			if (thumbnail.isSelected) classes.push('is-selected')
			return classes
		},
		getThumbnailUrl(thumbnail) {
			// study thumbnail (on left)
			if (thumbnail.imageId && thumbnail.storageLocation) return findThumbnailUrl(thumbnail)
			// matched study thumbnail (on right)
			if (thumbnail.imageId) {
				const studyImage = this.studyImages.find(({ imageId }) => imageId === thumbnail.imageId)
				return findThumbnailUrl(studyImage)
			}
			// image view thumbnail (on right)
			return `data:image;base64,${thumbnail.thumbnail}`
		},
		selectThumbnail({ imageId, isSelected }) {
			this.deselectThumbnail()
			const thumbnail = this.studyImages.find(thumbnail => thumbnail.imageId === imageId)
			thumbnail.isSelected = !isSelected
		},
		deselectThumbnail() {
			this.studyImages = this.studyImages.map(thumbnail => ({ ...thumbnail, isSelected: false }))
		},
		onDragStart(thumbnail, event) {
			this.selectThumbnail({ ...thumbnail, isSelected: false })
			event.dataTransfer.setData('text', '') // not used, but required for Firefox
			if (event._dndHandle && event.dataTransfer.setDragImage) {
				const ghostImage = event.target.closest('[draggable="true"]')
				event.dataTransfer.setDragImage(ghostImage, 0, 0)
			}
		},
		onImageViewClick(imageView) {
			if (this.selectedStudyImage) this.assignImageToView(imageView)
		},
		onImageViewDragOver(event) {
			event.dataTransfer.dropEffect = 'move'
			if (!event.target || !event.target.classList) return
			if (event.target.classList.contains('thumbnail')) event.target.classList.add('is-drag-over')
		},
		onImageViewDragLeave(event) {
			event.dataTransfer.dropEffect = 'move'
			if (!event.target || !event.target.classList) return
			if (event.target.classList.contains('thumbnail')) event.target.classList.remove('is-drag-over')
		},
		onImageViewDrop(imageView, event) {
			event.target.classList.remove('is-drag-over')
			this.assignImageToView(imageView)
		},
		async assignImageToView({ reportTemplateImageViewId, isExtra }) {
			if (!this.selectedStudyImage) return
			const reportImages = this.reportImages.slice()
			if (reportTemplateImageViewId) {
				const imageView = reportImages.find(image => {
					return image.reportTemplateImageViewId === reportTemplateImageViewId
				})
				imageView.imageId = this.selectedStudyImage.imageId
			} else if (isExtra) {
				let extraReason = ''
				if (this.service.isExtraReasonRequired) {
					extraReason = await openPromptDlg({
						buttonLabel: 'OK',
						prompt: 'Please enter a reason for the extra image:',
						defaultValue: this.selectedStudyImage.simpleName,
						type: 'textarea',
					})
				}
				if (extraReason === undefined) return
				reportImages.push({
					reportTemplateImageViewId: '',
					imageId: this.selectedStudyImage.imageId,
					extraReason: extraReason || this.selectedStudyImage.simpleName,
				})
			}
			this.reportImages = reportImages
			this.deselectThumbnail()
		},
		addRemainingAsExtraImages() {
			const remainingAsExtras = this.unmatchedStudyImages.map(image => ({
				reportTemplateImageViewId: '',
				imageId: image.imageId,
				extraReason: '',
			}))
			this.reportImages = [...this.reportImages, ...remainingAsExtras]
		},
		unassignImage(reportImage) {
			if (reportImage.reportTemplateImageViewId) {
				reportImage.imageId = null
				this.reportImages = this.reportImages.slice()
			}
			if ('extraReason' in reportImage) {
				this.reportImages.splice(this.reportImages.indexOf(reportImage), 1)
			}
		},
		async reportImagesNotMatching() {
			try {
				await service.imagesNotMatching({
					consultantId: this.$route.query.consultantId,
					saleId: this.service.sale.id,
					studyIds: this.$route.query.studyId.split(','),
				})
			} finally {
				showAlert(
					`Asteris personnel have been notified of the problem. ` +
						`Multiple submissions of images from the same modality device will not ` +
						`increase the priority of this issue.  Thank you for your patience.`
				)
			}
		},
	},
}
</script>

<style lang="scss" scoped>
@import '~@styles/_vars.scss';

.instructions {
	padding-top: 16px;
	& + .instructions {
		padding-top: 8px;
	}
}
.touch-only {
	display: none;
	@media (hover: none) {
		display: initial;
	}
}
.mouse-only {
	display: none;
	@media not all and (hover: none) {
		display: initial;
	}
}
.matching-panes {
	display: flex;
	margin: 0 -16px;
	& > * {
		padding: 16px;
		min-width: 50%;
		width: 50%;
		height: 100%;
	}
	@media (max-width: $mqLarge) {
		flex-direction: column;
		& > * {
			width: 100%;
		}
	}
	a {
		display: block;
		margin-top: 8px;
		user-select: none;
		cursor: pointer;
		font-weight: 400;
		&:hover {
			text-decoration: underline;
		}
	}
}
.thumbnail-pane {
	display: flex;
	flex-flow: row wrap;
	align-content: flex-start;
	height: 60vh;
	max-height: 60vh;
	overflow-y: auto;
	margin-top: 8px;
	padding: 2px;
	background: var(--secondary-bg);
	border: 1px solid var(--secondary-border);
	@media (max-width: $mqLarge) {
		height: 28vh;
		max-height: 28vh;
	}
}
.thumbnail {
	display: flex;
	position: relative;
	align-items: center;
	justify-content: center;
	width: 125px;
	height: 125px;
	background-size: contain;
	background-color: black;
	background-position: center;
	background-repeat: no-repeat;
	overflow: hidden;
	margin: 4px;
	user-select: none;
	color: #fff;
	padding: 3px;
	text-shadow: 1px 1px 2px #000;
	opacity: 0.9;
	cursor: pointer;
	.icon {
		filter: drop-shadow(1px 1px 2px #000);
	}
	&:hover {
		opacity: 1;
	}
	& > * {
		z-index: 1;
	}
	.dim-overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: var(--card-bg);
		opacity: 0.75;
		z-index: 0;
		pointer-events: none; // prevent firing thumbnail dragleave event
	}
	&.is-matched {
		cursor: default;
		&:hover {
			opacity: 0.9;
		}
	}
	&.is-unmatched {
		color: var(--primary-label);
		background: var(--checkbox-unchecked-border);
		text-shadow: 1px 1px 2px var(--checkbox-unchecked-border);
		cursor: default;
		&:hover {
			opacity: 0.9;
		}
	}
	&.is-selected {
		border: 3px solid var(--checkbox-checked-border);
		padding: 0;
	}
	&.is-selected,
	&.is-target {
		cursor: pointer;
	}
	&.is-target .dim-overlay {
		border: 2px solid var(--checkbox-checked-border);
	}
	&.is-target.is-drag-over .dim-overlay,
	&.is-target:hover .dim-overlay {
		border-bottom-width: 4px;
		opacity: 0.9;
	}
}
.clear-image {
	position: absolute;
	top: 0;
	right: 0;
	font-size: 30px;
	opacity: 0.85;
	cursor: pointer;
	&:hover {
		opacity: 1;
	}
}
.thumbnail-text {
	text-align: center;
	font-weight: 700;
	font-size: 0.9em;
	word-break: break-word;
	width: 100%;
	padding: 4px;
	pointer-events: none; // prevent firing thumbnail dragleave event
	.thumbnail-status {
		font-size: 0.9em;
		padding-top: 2px;
	}
}
</style>
