<template>
	<modal-wrapper @close="close" @appear="isVisible = true">
		<div class="lightbox" :style="{ backgroundImage: `url(${url})` }" @click.stop="onClick"></div>
	</modal-wrapper>
</template>

<script>
import ModalWrapper from '@components/ModalWrapper.vue'
import { dlg } from '@utils/dlgUtils'
import { findImageUrlFromImage } from '@utils/urlUtility'

const ImageLightbox = {
	name: 'ImageLightbox',
	components: {
		ModalWrapper,
	},
	props: {
		image: {
			type: Object,
			required: true,
		},
	},
	data() {
		return {
			isVisible: false,
		}
	},
	computed: {
		url() {
			return findImageUrlFromImage(this.image)
		},
	},
	methods: {
		onClick(e) {
			// prevent accidentally closing dialog before it is visible
			if (this.isVisible) this.close()
		},
		close() {
			dlg.close(this)
		},
	},
}
export default ImageLightbox

export function openLightbox(image) {
	return dlg.open(ImageLightbox, { image })
}
</script>

<style lang="scss" scoped>
.lightbox {
	height: 85vh;
	max-height: 85vh;
	width: 85vw;
	max-width: 85vw;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	overflow: hidden;
	cursor: pointer;
	text-align: center;
	box-shadow: none;
}
</style>
